
import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';
import { handleErrorUI, handleSuccessUI } from '@/util/error';
import Product from '@/models/Product';
import { convertFileToBase64, generateImageDataUrl } from '@/util/file';
import { map } from 'lodash';
import Image from '@/models/Image';
import { validateMandatoryFields } from '@/util/validation';

export default {
  mixins: [CommonMixin, FormMixin],
  data() {
    return {
      loading: true,
      submitting: false,
      product: {},
      currencies: [],
      productTypes: [],
      cachedImages: new Map<string, string>()
    };
  },
  computed: {
    editing() {
      return this.productId != null;
    },
    title() {
      return this.productId ? 'Edit Product' : 'Add Product';
    },
    productId(): string | null {
      return this.$route.params.id;
    },
    productImage() {
      const { image } = this.product;
      if (image == null || image.path == null) return [];
      return [image];
    },
    productTypeOptions() {
      return map(this.productTypes, type => ({
        label: type,
        value: type
      }));
    },
    currencyOptions() {
      return map(this.currencies, currency => ({
        label: currency.code,
        value: currency
      }));
    },
    canSubmit() {
      return validateMandatoryFields(this.product, [
        'name',
        'code',
        'type',
        'description',
        'active',
        'price',
        'currency'
      ]);
    }
  },
  mounted() {
    this.loading = true;
    const promises: Promise<unknown>[] = [];
    if (this.productId) {
      promises.push(
        this.$repo.product
          .getProduct(this.productId)
          .then(product => (this.product = product), handleErrorUI)
      );
    }
    promises.push(
      this.$repo.product
        .getProductTypes()
        .then(
          productTypes => (this.productTypes = productTypes),
          handleErrorUI
        ),
      this.$repo.core
        .getCurrencies()
        .then(currencies => (this.currencies = currencies), handleErrorUI)
    );
    Promise.all(promises).then(() => (this.loading = false));
  },
  methods: {
    async productImageUploadHandler(file: File) {
      const path = await this.$repo.product.uploadProductImage(file);
      this.cachedImages.set(path, await convertFileToBase64(file));
      return path;
    },
    async fetchProductImage(path: string) {
      if (this.cachedImages.has(path)) {
        return this.cachedImages.get(path);
      } else {
        return await this.$repo.product.downloadProductImage(path);
      }
    },
    formatProductImage(data: string, url: string) {
      if (this.cachedImages.has(url)) return `url("${data}")`;
      return generateImageDataUrl(data, url);
    },
    updateProductImage(images: Image[]) {
      let image = {};
      if (images && images.length > 0) {
        image = {
          path: images.pop().path
        };
      }
      this.product = {
        ...this.product,
        image
      };
    },
    updateField(product: Partial<Product>) {
      this.product = { ...this.product, ...product };
    },
    async submitForm() {
      this.submitting = true;
      try {
        await this.$repo.product.upsertProduct(this.product as Product);
        handleSuccessUI('Product saved');
        this.$router.push({ name: 'management:product' });
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.submitting = false;
      }
    }
  }
};
